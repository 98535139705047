import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image";

import NiceDate from "../components/niceDate"

const PostLink = ({ props, category, classes, card }) => (
  <Link to={`/blog/${props.slug}`} className={classes ? classes : 'column is-one-third'}>
    <div className={card ? card : 'featured__card card is-hoverable'}>
      {props.featuredImage &&
        <Img
          fluid={props.featuredImage.imageFile.childImageSharp.fluid}
          alt={props.altText}
          className="featured__image"
        />
      }
      <div className="featured__inner">
        {category &&
          <div className="featured__category" dangerouslySetInnerHTML={{ __html: category.edges[0].node.name }} />
        }
        {props.title &&
          <div className="featured__title" dangerouslySetInnerHTML={{ __html: props.title }} />
        }
        {props.date &&
          <div className="featured__date has-text-grey">
            <NiceDate date={props.date} />
          </div>
        }
      </div>
    </div>
  </Link>
)

export default PostLink
